import { useEffect } from 'react'
import GeoMapContainer from '@/containers/common/GeoMapContainer'

/**
 * Todo:
 * 용산구 원효전자상가 사이트에서만 실험적으로 동작하는 실내맵 서비스 입니다.
 * 앞으로 실내맵 서비스를 하는 방향과는 무관하여 일회성의 코드입니다.
 */
const useTempIndoorMap = () => {
  const { mapSite, overlayImage, geoMap } = GeoMapContainer.useContainer()

  useEffect(() => {
    if (mapSite && geoMap && mapSite.neubieGoSiteNumber === 'NB_YONGSAN_00') {
      geoMap.setZoom(19)
      overlayImage({
        geoMap,
        src: 'https://neubie-common.s3.ap-northeast-2.amazonaws.com/image/map_image/yongsan_indoor_map.png',
        bounds: {
          east: 37.5322284,
          west: 37.5349407,
          south: 126.9634514,
          north: 126.9573817
        }
      })
    }
  }, [geoMap, mapSite])
}

export default useTempIndoorMap
