import useTempIndoorMap from '@/hooks/useTempIndoorMap'

type GeoMapProps = {
  className: string
}

const GeoMap = ({ className }: GeoMapProps) => {
  /**
   * Todo:
   * 용산구 원효전자상가 사이트에서만 실험적으로 동작하는 실내맵 서비스 입니다.
   * 앞으로 실내맵 서비스를 하는 방향과는 무관하여 일회성의 코드입니다.
   */
  useTempIndoorMap()

  return <div id="map" className={className} />
}

export default GeoMap
